* {
    box-sizing: border-box
}

body {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
}

/* /*fonts* */
@font-face {
    font-family: 'TildaSans';
    src: url('./fonts/TildaSans-Bold.woff2') format('woff2');
    font-weight: 700;
}

@font-face {
    font-family: 'TildaSans';
    src: url('./fonts/TildaSans-Regular.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'TildaSans';
    src: url('./fonts/TildaSans-Medium.woff2') format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: 'TildaSans';
    src: url('./fonts/TildaSans-Light.woff2') format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: 'TildaSans';
    src: url('./fonts/TildaSans-ExtraBold.woff2') format('woff2');
    font-weight: 800;
}


.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    margin-right: auto;
    margin-left: auto;
    padding: 16px 80px;
    font-family: 'TildaSans';
}

.header-title {
    display: flex;
    flex-direction: column;
}

.header_title {
    cursor: pointer;
}


.title {
    font-size: 18px;
    font-weight: 900;
    color: #1C0A3F;
}

.subtitle {
    font-size: 14px;
    font-weight: 500;
    color: #877E99;
}

.form {
    max-width: 625px;
    margin-right: auto;
    margin-left: auto;
    padding: 48px;
    text-align: center;
    background-color: #F7F7F9;
    font-family: 'TildaSans';
}

.form_title {
    margin: 0;
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 400;
    color: #1C0A3F;
}

.wrap {
    display: flex;
    flex-direction: column;
    -ms-flex-align: start;
}

.input_style {
    width: 100%;
    padding: 20px;
    outline: none;
    border: 1px solid #DCDCEA;
    border-radius: 2px;
}

.input_style::placeholder {
    font-size: 18px;
    font-weight: 500;
    color: #C5CEED;
}

.input_style-err {
    width: 100%;
    padding: 20px;
    outline: none;
    border: 1px solid #E54D4D;
    border-radius: 2px;
}

.input_wrap {
    text-align: start;
}

.btn_style {
    width: 100%;
    margin-top: 24px;
    padding: 20px;
    outline: none;
    border: none;
    border-radius: 2px;
    background-color: #1C0A3F;
    color: #FEFFFF;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.label_style {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #877E99;
}

.label_password {
    display: inline-block;
    margin-top: 24px;
}

.err_message {
    margin: 0;
    margin-left: 20px;
    color: #E54D4D;
    font-size: 12px;
    font-weight: 400;
}

.list_rooms {
    width: 200px;
    background-color: #F7F7F9;
    padding: 15px 30px 15px 50px;
    background-image: url('./images/Vector.png');
    background-repeat: no-repeat;
    background-position: 20px 20px;
    cursor: pointer;
    color: #1C0A3F;
}

.user_avatar {
    position: relative;
    padding: 15px 20px 5px 50px;
    background-color: #F7F7F9;
    border-radius: 99px 2px 2px 99px;
    color: #878980;
    font-size: 16px;
    font-weight: 400;
    background-image: url('./images/UserAvatar.png');
    background-repeat: no-repeat;
    background-position: 1px center;
    text-align: center;

}

.admin_name {
    margin-right: 5px;
    color: #1C0A3F;
}

.icon_select {
    position: absolute;
    top: 19px;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    top: 70px;
    right: 80px;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: 0.3s;
    border: 1px solid rgba(0, 65, 102, 0.2);
    box-shadow: rgba(0, 32, 51, 0.12);
    border-radius: 2px;
    padding: 24px 8px 0 15px;
    background-color: #fff;
    z-index: 1;
}

.dropdown-menu.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.dropdown-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.dropdown-item {
    color: #1C0A3F;
    font-size: 18px;
    margin-right: 40px;
    cursor: pointer;
}

.test:hover {
    border-radius: 2px;
    background-color: rgba(0, 32, 51, 0.12);
}



.test {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
}

.cover-menu_wrap {
    padding: 0 80px 48px;
}

.blur {
    filter: blur(4px);
}

.cover-margin {
    margin-bottom: 48px;
}

.groups {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F9;
    border-radius: 2px;
    padding: 48px 90px;
    background-image: url('./images/groups.png');
    background-repeat: no-repeat;
    background-position: 48px center;
    max-width: 405px;
    cursor: pointer;
    margin-bottom: 20px;
}

.events {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F9;
    border-radius: 2px;
    padding: 48px 90px;
    background-image: url('./images/events.png');
    background-repeat: no-repeat;
    background-position: 48px center;
    max-width: 405px;
    cursor: pointer;
}

.rest {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F9;
    border-radius: 2px;
    padding: 48px 90px;
    background-image: url('./images/rest.png');
    background-repeat: no-repeat;
    background-position: 48px center;
    max-width: 405px;
    cursor: pointer;
}

.menu-title {
    display: block;
    margin-left: 60px;
    margin-bottom: 4px;
    font-family: 'TildaSans';
    font-size: 20px;
    font-weight: 700;
    color: #1C0A3F;
}

.menu-edit {
    display: block;
    margin-left: 60px;
    font-family: 'TildaSans';
    font-size: 16px;
    font-weight: 400;
    color: #877E99;
}

.menu-items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.modal-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 32, 51, 0.15);
    backdrop-filter: blur(4px);
    z-index: 1;
}

.modal {
    position: relative;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 625px;
    padding: 48px;
    text-align: center;
    font-family: 'TildaSans';
    border-radius: 2px;
    z-index: 1;
}

button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.btn-wrap {
    display: flex;
    justify-content: space-between;
}

.btn-exit {
    width: 50%;
    padding: 20px;
    background-color: #E54D4D;
    color: #fff;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 600;
    margin-right: 8px;
    cursor: pointer;
    font-family: 'TildaSans';
}

.btn-cancel {
    width: 50%;
    padding: 20px;
    background-color: #fff;
    color: #1C0A3F;
    border: 1px solid #1C0A3F;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'TildaSans';
}

.modal-title {
    margin: 0;
    margin-bottom: 24px;
    font-family: 'TildaSans';
    font-size: 32px;
    font-weight: 400;
    color: #1C0A3F;
}

.modal-question {
    margin: 0;
    margin-bottom: 10px;
    color: #1C0A3F;
    font-size: 18px;
    font-weight: 500;
}

.modal-text {
    margin: 0;
    margin-bottom: 33px;
    color: #877E99;
    font-size: 14px;
    font-weight: 500;
}

.btn-close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.modal-position {
    position: absolute;
    top: 0 center;

}

.test2 {
    position: relative;
}

.test3 {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.test4 {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

.group-title-wrap {
    display: flex;
    align-items: center;
    padding: 0 80px;
    font-family: 'TildaSans';
    margin-bottom: 20px;
}

.group-title {
    margin-right: 18px;
    font-size: 32px;
    font-weight: 700;
    color: #1C0A3F;
}

.btn-plus {
    margin-right: 6px;
    cursor: pointer;
}

.btn-basket {
    cursor: pointer;
}

.calendar {
    display: flex;
}

.calendar-wrap {
    display: flex;
    align-items: center;
    padding: 20px 80px;
    font-family: 'TildaSans';
}

.enter-group {
    background-color: #494B42;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    padding: 3px 20px;
    cursor: pointer;
}

.text {
    font-family: 'TildaSans';
    text-align: center;
    font-size: 32px;
    font-weight: 400;
    color: #1C0A3F;
    padding: 95px;
}

.react-datepicker__input-container input {
    width: 270px;
    padding: 10px;
    background-color: #FEFFFF;
    border: none;
    outline: none;
    border-radius: 2px;
    color: #4C4360;
    font-size: 13px;
}


.card-container {
    padding: 20px 80px;
}

.card-wrap-green {
    display: flex;
    align-items: center;
    width: 400px;
    padding: 52px 48px;
    background-color: #1C0A3F;
    color: #fff;
    font-family: 'TildaSans';
    border-radius: 2px;
    cursor: pointer;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.card-item {
    margin-bottom: 24px;
}

.card-item:not(:last-child) {
    margin-right: 25px;
}

.card-info {
    display: flex;
    flex-direction: column;
}

.group-img {
    margin-right: 16px;
}

.date-group {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 5px;
}

.date-group-gray {
    font-size: 20px;
    font-weight: 700;
    color: #1C0A3F;
    margin-bottom: 5px;
}

.status {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.card-wrap-gray {
    display: flex;
    align-items: center;
    width: 400px;
    padding: 52px 48px;
    background-color: #F7F7F9;
    font-family: 'TildaSans';
    border-radius: 2px;
    cursor: pointer;
}

.period-wrap {
    padding: 16px 80px;
    font-family: 'TildaSans';
}

.group-period {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.date-group-period {
    margin-right: 15px;
    font-size: 32px;
    font-weight: 700;
    color: #1C0A3F;
}

.btn-delete-group {
    cursor: pointer;
    margin-right: 20px;
}

.send-code {
    max-width: 265px;
    text-align: center;
    padding: 5px 10px;
    background-color: #F7F8F5;
    color: #494B42;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

.list-wrap {
    padding: 16px 80px;
    font-family: 'TildaSans';
}

.header-list-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.list-item {
    font-size: 18px;
    font-weight: 900;
    color: #494B42;
}

.list-item-home {
    padding: 0 80px 0 50px;
    color: #1C0A3F;
}

.list-item-room {
    padding: 0 50px 0 0;
}

.list-item-fio {
    padding: 0 250px 0 0;
    color: #1C0A3F;
}

.list-item-mail {
    padding: 0 300px 0 0;
    color: #1C0A3F;
}

.list-item-start {
    padding: 0 40px 0 0;
    color: #1C0A3F;
}

.list-item-end {
    padding: 0 50px 0 0;
    color: #1C0A3F;
}

.person-list-wrap {
    display: flex;
    /* align-items: center; */
    background-color: transparent;
    /* height: fit-content; */
}

.person-list-wrap:nth-child(odd) {
    background-color: #F7F7F9;
}

.person-item-fio {
    width: 345px;
    padding: 8px 30px;
    /* background-color: #F7F7F9; */
    color: #4C4360;
    cursor: pointer;
}

.person-item-fio:hover {
    background-color: #E7E5EB;
}

.person-item-mail {
    width: 380px;
    padding: 8px 30px;
    /* background-color: #F7F7F9; */
    color: #4C4360;
    height: 35px;
}

.person-item-start {
    padding: 8px 30px;
    /* background-color: #F7F7F9; */
    color: #4C4360;
}

.person-item-end {
    padding: 8px 30px;
    /* background-color: #F7F7F9; */
    color: #4C4360;
}

.delete-person {
    padding: 2px 35px;
    /* background-color: #F7F7F9; */
    cursor: pointer;
}

.add-person {
    background-color: #E7E5EB;
    height: 40px;
}

.add-person-bg {
    cursor: pointer;
}

.settle-person {
    padding: 8px 55px;
    /* background-color: #F7F7F9; */
    color: #1C0A3F;
    font-weight: 600;
    height: 35px;
    width: 134px;
}

.modal-wrap-add-group {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 32, 51, 0.15);
    backdrop-filter: blur(4px);
    transform: scale(0);
    z-index: 1;
}

.modal-wrap-add-group.active {
    transform: scale(1);
}

.plus-user {
    z-index: 0;
}



.modal-add-group {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F7F7F9;
    margin-left: auto;
    margin-right: auto;
    max-width: 842px;
    padding: 48px;
    /* text-align: center; */
    font-family: 'TildaSans';
    border-radius: 2px;
    z-index: 1;
}

.modal-add-group-title {
    font-size: 32px;
    color: #1C0A3F;
    margin-right: 300px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.dropzone {
    margin-bottom: 20px;
    padding: 30px;
    border: 2px dashed rgba(201, 216, 149, 0.4);
    border-radius: 2px;
    text-align: center;
}

.flex-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.files-btn-wrap {
    display: flex;
    justify-content: flex-end;
}

.modal-style {
    margin-bottom: 20px;
}

.modal-style2 {
    margin-bottom: 10px;
}

.modal-style3 {
    margin-bottom: 20px;
}

.date-period-start,
.date-period-end {
    color: #877E99;
}

.date-period-start {
    margin-right: 200px;
}

.style-text {
    margin: 0;
    margin-bottom: 10px;
    color: #F7F8F5;
    font-size: 18px;
    font-weight: 400;
}

.style-text2 {
    margin: 0;
    margin-bottom: 17px;
    color: #CFD0CA;
    font-size: 14px;
}

.btn-upload {
    padding: 10px 20px;
    background-color: #595A54;
    color: #C9D895;
    border-radius: 2px;
    border: none;
    outline: none;
}

.modal-btn-cancel {
    background-color: transparent;
    border: none;
    outline: none;
    background-color: #F7F7F9;
    color: #4C4360;
    font-family: 'TildaSans';
    font-size: 18px;
    padding: 10px 30px;
    cursor: pointer;
}

.modal-btn-save {
    background-color: #E7E5EB;
    border: none;
    outline: none;
    color: rgba(72, 64, 88, 0.5);
    font-family: 'TildaSans';
    font-size: 18px;
    padding: 10px 30px;
    border-radius: 2px;
    cursor: pointer;
}

.modal-btn-save-active {
    background-color: #1C0A3F;
    border: none;
    outline: none;
    color: #FEFFFF;
    font-family: 'TildaSans';
    font-size: 18px;
    padding: 10px 30px;
    border-radius: 2px;
}

.add-group-btn-close {
    cursor: pointer;
}

.add-person-mail {
    width: 100%;
}

.select-home {
    width: 270px;
}

.select-room {
    width: 270px;
}

.style-option {
    width: 240px;
}

.label-style-person {
    font-size: 14px;
    color: #877E99;
    margin-bottom: 5px;
}

.add-person-fio,
.add-person-mail,
.select-home,
.select-room {
    padding: 10px;
    background-color: #FEFFFF;
    border: none;
    outline: none;
    border-radius: 2px;
    color: #484058;
    font-size: 13px;
}

/* .add-person-fio.error {
    border: 1px solid red;
  } */

.margin-right:not(:last-child) {
    margin-right: 10px;
}

.fio-wrap {
    margin-bottom: 20px;
}

.add-person-mail {
    margin-bottom: 20px;
}

.home-room {
    margin-bottom: 20px;
}

.add-person-fio::placeholder {
    color: rgba(72, 64, 88, 0.5);
}

.add-person-mail::placeholder {
    color: #CFD0CA;
}

.btn-wrap-add-person {
    display: flex;
    justify-content: flex-end;
}

.btn-add-person-cancel {
    background-color: transparent;
    border: none;
    outline: none;
    background-color: #F7F7F9;
    color: #4C4360;
    font-family: 'TildaSans';
    font-size: 18px;
    padding: 10px 30px;
}

.btn-add-person-save {
    background-color: #E7E5EB;
    color: #484058;
    border: none;
    outline: none;
    color: #878980;
    font-family: 'TildaSans';
    font-size: 18px;
    padding: 10px 30px;
    border-radius: 2px;
}

.btn-add-person-save-active {
    background-color: #1C0A3F;
    color: #fff;
    border: none;
    outline: none;
    font-family: 'TildaSans';
    font-size: 18px;
    padding: 10px 30px;
    border-radius: 2px;
}

.wrap-datepicker {
    margin-bottom: 20px;
}

.add-person-btn-close {
    cursor: pointer;
}

.select-home option {
    background-color: #E54D4D;
}

.modal-wrap-delete-person {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 32, 51, 0.15);
    ;
    backdrop-filter: blur(4px);
    transform: scale(0);
}

.modal-wrap-delete-person.active {
    transform: scale(1);
}

.modal-delete-person {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F7F7F9;
    margin-left: auto;
    margin-right: auto;
    max-width: 842px;
    padding: 48px;
    text-align: center;
    font-family: 'TildaSans';
    border-radius: 2px;
    z-index: 1;
}

.modal-delete-person-title {
    color: #1C0A3F;
    font-size: 32px;
}

.modal-style-delete-person {
    /* display: flex; */
    /* align-items: center; */
    margin-bottom: 20px;
    /* justify-content: space-between; */
}

.add-person-btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.center {
    text-align: center;
}

.delete-person-question {
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
    color: #877E99;
}

.delete-person-text {
    margin: 0;
    margin-bottom: 20px;
    font-size: 14px;
    color: #877E99;
}

.btn-wrap-delete-person {
    display: flex;
    justify-content: space-between;
}

.btn-delete-person {
    margin-right: 10px;
    padding: 5px 60px;
    border: none;
    outline: none;
    background-color: #E54D4D;
    color: #fff;
    border-radius: 2px;
    font-family: 'TildaSans';
    font-size: 18px;
    cursor: pointer;
}

.btn-cancel-person {
    padding: 5px 60px;
    border: 1px solid #E7E5EB;
    outline: none;
    background-color: transparent;
    color: #4C4360;
    border-radius: 2px;
    font-family: 'TildaSans';
    font-size: 18px;
    cursor: pointer;
}

.modal-wrap-delete-group {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 32, 51, 0.15);
    backdrop-filter: blur(4px);
    transform: scale(0)
}

.modal-wrap-delete-group.active {
    transform: scale(1)
}

.month-picker-select {
    background-color: #F7F7F9;
    border: 1px solid #F0F3F9;
    color: #1C0A3F;
    outline: none;
    padding: 10px 100px 10px 10px;
    border-radius: 2px;
    font-family: 'TildaSans';
    cursor: pointer;
    font-size: 16px;
}

a {
    text-decoration: none;
}

.filter-wrap {
    display: flex;
}

.chekin-date-wrap {
    padding: 20px 20px 20px 0;
}

/* events */
.modal-wrap-add-event {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 32, 51, 0.15);
    backdrop-filter: blur(4px);
    transform: scale(0);
}

.modal-wrap-add-event.active {
    transform: scale(1);
}

.modal-add-event {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F7F7F9;
    margin-left: auto;
    margin-right: auto;
    max-width: 842px;
    padding: 48px;
    text-align: center;
    font-family: 'TildaSans';
    border-radius: 2px;
    z-index: 1;
}

.btn-add-event-wrap {
    display: flex;
}

.btn-add-event {
    margin-right: 10px;
    padding: 15px 60px;
    background-color: #E7E5EB;
    color: #4C4360;
    font-size: 16px;
    font-weight: 600;
    font-family: 'TildaSans';
}

.btn-create-new-event {
    padding: 15px 100px;
    background-color: #4C4360;
    color: #E7E5EB;
    font-size: 16px;
    font-weight: 600;
    border-radius: 2px;
    font-family: 'TildaSans';
}

.modal-add-event-title {
    margin-right: auto;
    font-size: 32px;
    color: #1C0A3F;
    border-radius: 2px;
}

.add-event-btn-close {
    position: absolute;
    top: 55px;
    right: 45px;
    cursor: pointer;
}

.event-card-container {
    display: flex;
    /* justify-content: space-between; */
    padding: 0 80px;
    font-family: 'TildaSans';
    height: 450px;
}

.event-card-wrap {
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #F7F7F9;
    border-radius: 2px;
    height: fit-content;
}

.event-card-wrap:not(:last-child) {
    margin-right: 10px;
}

.geo {
    margin-bottom: 10px;
    font-size: 12px;
    color: #4C4360;
}

.geo-text {
    margin-bottom: 10px;
    font-size: 14px;
    color: rgba(72, 64, 88, 0.5);
}

.event-edit-btn {
    width: 100%;
    padding: 5px;
    background-color: #E7E5EB;
    border-radius: 2px;
    color: #4C4360;
    font-size: 14px;
    font-weight: 600;
}

.event-img {
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;
    width: 180px;
    height: 180px;
}

.event-name {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #1C0A3F;
}

.event-hour {
    position: absolute;
    padding: 4px;
    top: 160px;
    left: 20px;
    font-size: 12px;
    background-color: #F7F7F9;
}

.event-date {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: #1C0A3F;
}

.flex-column {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.event-wrap {
    display: flex;
}

.create-event-title {
    font-size: 32px;
    color: #1C0A3F;
}

.add-event-name,
.add-event-telegram,
.add-event-descr,
.add-event-date,
.add-event-repeat,
.add-event-time-start,
.add-event-time-end,
.add-event-place,
.add-event-meet {
    width: 270px;
    border: none;
    outline: none;
    padding: 10px;
}

.add-event-name::placeholder {
    font-size: 14px;
    font-family: 'TildaSans';
    color: rgba(72, 64, 88, 0.5);
}

.add-event-telegram::placeholder {
    font-size: 14px;
    font-family: 'TildaSans';
    color: rgba(72, 64, 88, 0.5);
}

.add-event-descr::placeholder {
    font-size: 14px;
    font-family: 'TildaSans';
    color: rgba(72, 64, 88, 0.5);
}

.add-event-place::placeholder {
    font-size: 14px;
    font-family: 'TildaSans';
    color: rgba(72, 64, 88, 0.5);
}

.add-event-meet::placeholder {
    font-size: 14px;
    font-family: 'TildaSans';
    color: rgba(72, 64, 88, 0.5);
}

.add-event-descr {
    width: 590px;
    height: 150px;
    resize: none;
}

.margin-bottom {
    margin-bottom: 20px;
}

.btn-event-cancel {
    background-color: transparent;
    padding: 10px 30px;
    font-family: 'TildaSans';
    font-size: 18px;
    font-weight: 600;
    color: #4C4360;
    margin-right: 10px;
}

.btn-event-save {
    background-color: #E7E5EB;
    padding: 10px 30px;
    font-family: 'TildaSans';
    font-size: 18px;
    font-weight: 600;
    color: rgba(72, 64, 88, 0.5);
    border-radius: 2px;
}

.btn-event-save.active {
    background-color: #1C0A3F;
    padding: 10px 30px;
    font-family: 'TildaSans';
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border-radius: 2px;
}

.btn-event-wrap {
    display: flex;
    justify-content: flex-end;
}

.descr-title-wrap {
    margin-bottom: 20px;
    padding: 0 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.descr-title {
    margin-right: 10px;
    font-family: 'TildaSans';
    font-size: 32px;
    font-weight: 700;
    color: #1C0A3F;
}

.btn-descr-edit {
    margin-right: 10px;
    cursor: pointer;
}

.btn-descr-cancel {
    margin-right: 10px;
    cursor: pointer;
}

.btn-descr-delete {
    cursor: pointer;
}

.descr-time-date {
    margin-bottom: 30px;
    padding: 0 80px;
    font-family: 'TildaSans';
    font-size: 18px;
    color: #4C4360;
}

.descr-time {
    margin-right: 15px;
    font-weight: 600;
    color: #1C0A3F;
}

.descr-date {
    color: #1C0A3F;
}

.descr-info {
    padding: 0 80px;
    margin-bottom: 30px;
}

.descr-item {
    margin-right: 10px;
    padding: 10px;
    background-color: #F7F7F9;
    color: #4C4360;
    font-size: 12px;
    font-family: 'TildaSans';
    border-radius: 8px;
}

.descr-text {
    padding: 0 80px;
    font-size: 18px;
    font-family: 'TildaSans';
    color: #4C4360;
}

.btn-cancel-event {
    margin-right: 10px;
    padding: 5px 90px;
    border: 1px solid #E54D4D;
    outline: none;
    background-color: transparent;
    color: #E54D4D;
    border-radius: 2px;
    font-family: 'TildaSans';
    font-size: 18px;
    cursor: pointer;
}

.btn-not-cancel {
    padding: 5px 90px;
    border: 1px solid #E7E5EB;
    outline: none;
    background-color: transparent;
    color: #4C4360;
    border-radius: 2px;
    font-family: 'TildaSans';
    font-size: 18px;
    cursor: pointer;
}

.modal-select-event {
    width: 700px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #F7F7F9;
    margin-left: auto;
    margin-right: auto;
    max-width: 842px;
    padding: 48px;
    font-family: 'TildaSans';
    border-radius: 2px;
    z-index: 1;
}

.select-event {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 1px solid #DCDCEA;
    border-radius: 2px;
    margin-bottom: 20px;
}

.select-event-label {
    margin-bottom: 10px;
}

.pagination-container {
    padding: 0 80px;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.pagination-list {
    display: flex;
    justify-content: center;
    list-style: none;
    align-items: center;
}

.card-wrap {
    display: flex;
}

.card-wrap-all {
    display: flex;
    flex-direction: column;
}

.card-wrap-all:not(:last-child) {
    margin-right: 10px;
}

.btn-left {
    margin-right: 30px;
}

.btn-right {
    margin-left: 30px;
}

.page-number {
    font-family: 'TildaSans';
    font-size: 18px;
    color: #4C4360;
}

.modal-wrap-add-event-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 32, 51, 0.15);
    backdrop-filter: blur(4px);
    transform: scale(0);
    z-index: 2;
}

.modal-wrap-add-event-btn.active {
    transform: scale(1);
}

.person-info {
    display: flex;
    padding: 0 80px;
    font-family: 'TildaSans';
    font-size: 12px;
    color: #1C0A3F;
    margin-bottom: 20px;
}

.person-code {
    display: flex;
    margin-right: 100px;
}

.person-date {
    display: flex;
    margin-right: 100px;
}

.person-icon {
    margin-right: 15px;
}

.bold {
    font-weight: 700;
}

.houses-container {
    padding: 0 80px;
    font-family: 'TildaSans';
    display: flex;
    flex-wrap: wrap;
    height: 650px;
    justify-content: center;
    width: 1500px;
}

.house-card {
    width: 300px;
    margin-bottom: 20px;
}

.house-card {
    margin-right: 20px;
}

.house-header {
    padding: 20px;
    background-color: #E7E5EB;
    color: #4C4360;
}

.house-header.free-house {
    padding: 20px;
    background-color: #1C0A3F;
    color: #FEFFFF;
}

.house-users {
    padding: 10px;
    background-color: #F7F7F9;
    min-height: 190px;
}

.code-img {
    width: 28px;
    height: 28px;
    margin-right: 10px;
}

.user-free {
    margin-right: 20px;
}

.edit-img {
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.code-fio {
    margin-right: auto;
}

.user {
    display: flex;
    padding: 10px;
    align-items: center;

}

.user-fio {
    font-weight: 700;
    color: #1C0A3F;
    font-size: 12px;
}

.user-code {
    color: #4C4360;
    font-size: 12px;
}

.free {
    color: #877E99;
    font-weight: 700;
    font-size: 12px;
}

.modal-wrap-houses {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(9, 10, 8, 0.5);
    backdrop-filter: blur(4px);
    transform: scale(0);
    font-family: 'TildaSans';
}

.modal-wrap-houses.active {
    transform: scale(1);
    z-index: 1;
}

.modal-houses {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #494B42;
    margin-left: auto;
    margin-right: auto;
    width: 1280px;
    padding: 48px;
    text-align: center;
    font-family: 'TildaSans';
    border-radius: 2px;
    z-index: 1;
}

.test-relative {
    position: relative;
}

.test-absolute {
    position: absolute;
    top: 320px;
    left: 280px;
    z-index: 1;
}

.test-absolute-2 {
    position: absolute;
    top: -350px;
    left: 1150px;
    z-index: 2;
    cursor: pointer;
}

.search-course-wrap {
    padding: 0 80px;
    font-family: 'TildaSans';
    margin-bottom: 20px;
}

.search-course {
    padding: 10px 170px 10px 30px;
    background-color:
        #F7F7F9;
    color: #878980;
    border: 1px solid #484058;
    border-radius: 2px;
    outline: none;
}

.search-course::placeholder {
    color:
        rgba(72, 64, 88, 0.5);
    font-family: 'TildaSans';
}

i.search-icon {
    width: 36px;
    height: 36px;
    line-height: 39px;
    text-align: center;
    margin-right: -33px;
    position: relative;
    /* z-index: 1; */
    float: left;
}

.dishes-title {
    color: #1C0A3F;
}

.dishes-list-wrap {
    padding: 0 80px;
    font-family: 'TildaSans';
}

.dishes-name,
.dishes-descr {
    color: #1C0A3F;
    font-size: 18px;
    font-weight: 900;
}

.dishes-name {
    padding: 5px 310px 5px 10px;
}

.dishes-descr {
    padding: 5px 100px 5px 10px;
}

.dishes-card {
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 10px;
    /* background-color: #F7F7F9; */
}

.dishes-card:nth-child(odd) {
    background-color: #F7F7F9;
}

.dishes-name-card {
    width: 400px;
}

.dishes-descr-card {
    width: 600px;
    margin-right: auto;
}

.dishes-edit {
    margin-right: 10px;
    cursor: pointer;
}

.dishes-delete {
    cursor: pointer;
}

.dishes-title-wrap {
    display: flex;
    margin-bottom: 20px;
}

.select-dish {
    width: 100%;
    /* margin-bottom: 20px; */
}

.name-dishes {
    width: 100%;
    margin-bottom: 20px;
}

.repeat-menu-wrap {
    display: flex;
    align-items: center;
}

.repeat-menu {
    padding: 10px 30px;
    background-color: #1C0A3F;
    color: #F7F7F9;
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    border-radius: 2px;
    cursor: pointer;
}

.wrap-create-menu {
    padding: 0 80px;
    font-family: 'TildaSans';
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.create-menu {
    background-color: #FEFFFF;
    color: #1C0A3F;
    font-size: 16p;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 2px;
    cursor: pointer;
    margin-right: 10px;
}

.create-menu.active {
    background-color: #1C0A3F;
    color: #FEFFFF;
    font-size: 16p;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 2px;
}

.choise-users {
    background-color: #FEFFFF;
    color: #1C0A3F;
    font-size: 16p;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 2px;
    cursor: pointer;
}

.choise-users.active {
    background-color: #1C0A3F;
    color: #fff;
}

.create-menu:hover {
    background-color: #1C0A3F;
    color: #fff;
}

.choise-users:hover {
    background-color: #1C0A3F;
    color: #fff;
}

.choise-users.active {
    background-color: #1C0A3F;
    color: #FEFFFF;
    font-size: 16p;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 2px;
}

.period-wrap {
    padding: 0 80px;
    font-family: 'TildaSans';
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.btn-item-period {
    background-color: #FEFFFF;
    color: #1C0A3F;
    font-size: 16p;
    font-weight: 600;
    padding: 10px 40px;
    border-radius: 2px;
    cursor: pointer;
}

.btn-item-period.active {
    background-color: #1C0A3F;
    color: #FEFFFF;
    font-size: 16p;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 2px;
}

.courses-time-wrap {
    display: flex;
    margin-bottom: 20px;
}

.input-time {
    padding: 5px 10px;
    border: 1px solid #494b4273;
    border-radius: 2px;
}

.margin {
    margin-right: 15px;
}

#course-start {
    width: 100px;
}

#course-end {
    width: 100px;
}

/* #course-start:before {
    content: 'Начало:';
    margin-right: .6em;
    color: #494b4273;
    font-size: 18px;
    font-family: 'TildaSans';
  } */

/* #course-end:before {
    content: 'Конец:';
    margin-right: .6em;
    color: #494b4273;
    font-size: 18px;
    font-family: 'TildaSans';
  } */

/* #course-end {
    margin-left: 15px;
  } */

.feedback-icon {
    margin-right: 15px;
}

.feedback {
    align-items: center;
    padding: 10px 30px 10px 30px;
    background-color: #F7F7F9;
    color: #1C0A3F;
    font-size: 16px;
    font-family: 'TildaSans';
    cursor: pointer;
}

.feedback-container {
    padding: 0 80px;
    display: flex;
    flex-wrap: wrap;
}

.feedback-card {
    width: 310px;
    padding: 15px;
    background-color: #F7F7F9;
    font-family: 'TildaSans';
    margin-right: 10px;
    margin-bottom: 10px;
}

.start-container {
    display: flex;
    margin-bottom: 5px;
}

.feedback-text {
    padding: 10px;
    height: 120px;
    border: 1px solid #CFD0CA;
    border-radius: 2px;
    overflow-y: scroll;
    font-family: 'TildaSans';
    color: #494B42;
}

.wrap-head {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.user-fio-feed {
    color: #1C0A3F;
    font-size: 15px;
    font-weight: 700;
}

.review-star-margin {
    margin-right: 7px;
}

.review-star.active {
    fill: #FBC96A;
}

.box {
    padding: 0 80px;
}

.btn-add-dish {
    cursor: pointer;
    margin-right: 30px;
}

.for-all-title {
    margin: 0;
    margin-bottom: 10px;
    font-family: 'TildaSans';
    color: #1C0A3F;
    font-size: 20px;
    font-weight: 700;
}

.for-all-card-dish {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #F7F7F9;
    width: 187px;
    border-radius: 2px;
    height: fit-content;
}

.for-all-card-name {
    display: inline-block;
    font-family: 'TildaSans';
    font-size: 18px;
    font-weight: 600;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.for-all-checkbox {
    margin-left: auto;
}

.for-all-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    min-height: 140px;
}

.choice-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.choice-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 187px;
    background-color: #F7F7F9;
    padding: 10px;
    font-family: 'TildaSans';
    margin-right: 15px;
    margin-bottom: 15px;
    height: fit-content;
}

.del-card {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.cat-choice {
    color: #1C0A3F;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.cat-choice-item {
    color: #1C0A3F;
    font-size: 14px;
    margin-bottom: 5px;
}

.btn-add-dish-big {
    width: 187px;
    height: 140px;
    margin-right: 15px;
    cursor: pointer;
}

.tea-house-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 140px;
}

.btn-menu-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    /* justify-content: space-between; */
}

.save-menu {
    padding: 10px 20px;
    background-color: #56d1b0;
    color: #484058;
    font-family: 'TildaSans';
    font-size: 18px;
    font-weight: 600;
}

.save-menu:hover {
    background-color: #1C0A3F;
    color: #FEFFFF;
}

.save-menu.active {
    background-color: #1C0A3F;
    color: #FEFFFF;
}

.modal-add-dish {
    width: 700px;
    text-align: start;
}

.add-dish-style-big {
    margin-bottom: 20px;
}

.label-style-dish {
    margin-top: 20px;
}

.add-alter-dish {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.delete-alter-dish {
    margin-right: auto;
    cursor: pointer;
}

.btn-add-dish-wrap {
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;
}

.btn-add-dish-wrap-small {
    margin-top: 20px;
    align-items: center;
    justify-content: flex-end;
}

.date-group-menu {
    margin-bottom: 20px;
    padding: 0 80px;
    font-family: 'TildaSans';
    color: #1C0A3F;
    font-size: 20px;
    font-weight: 700;
}

.breakfast-container,
.lunch-container,
.dinner-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.person-choice-wrap {
    display: flex;
    /* align-items: flex-start;
    flex-direction: column; */
    height: fit-content;
}

.person-choice {
    height: fit-content;
}

.no-choice {
    color: #E54D4D;
}

.item-fio-menu {
    padding: 0 200px 0 0;
}

.item-text {
    width: 300px;
    white-space: normal;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    cursor: default;

}

.item-text:hover {
    background-color: transparent;
}

.item-breakfast {
    padding: 0 230px 0 0;
}

.item-lunch {
    padding: 0 255px 0 0;
}

.alt-box {
    max-height: 200px;
    overflow-y: scroll;
}

/* ::-webkit-scrollbar {
    background: transparent; 
    background: rgba(233, 80, 80, 0.3);
    border-radius: 8px;
    width: 5px;
    min-height: 20px;
    display: none;
  } */

.save-checkbox {
    margin-right: 30px;
}

.label-time {
    font-family: 'TildaSans';
    color: #1C0A3F;
}

.dishes-container {
    height: 500px;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    background: transparent;
    background: rgba(47, 45, 45, 0.3);
    border-radius: 2px;
    width: 7px;
    /* height: 10px; */
    /* display: none; */
}

::-webkit-scrollbar-thumb {
    background-color: #585656;
    max-height: 5px;
}

.strelka {
    margin-right: auto;
    font-family: 'TildaSans';
}

.strelka-right {
    margin-right: 10px;
    font-family: 'TildaSans';
}

.modal-exit {
    z-index: 1;
}

.group-title-wrap.download {
    display: flex;
    align-items: center;
}

.btn-download {
    background-color: #1C0A3F;
    color: #FEFFFF;
    font-size: 16p;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 2px;
    font-family: 'TildaSans';
}

.btn-download:hover {
    background-color: #261052d2;
    color: #FEFFFF;
    font-size: 16p;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 2px;
    font-family: 'TildaSans';
}